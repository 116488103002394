module.exports = {
  proxy: {
    prefix: "/.netlify/functions",
    url: "http://localhost:9000",
  },
  siteMetadata: {
    title: 'Parkmuseerne',
    siteUrl: 'https://localhost:8000'
  },
  plugins: [
    `gatsby-plugin-sass`,
    `gatsby-plugin-sitemap`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-plugin-scroll-reveal`,
    {  
      resolve: `gatsby-plugin-scroll-reveal`,
      options: {
        threshold: 0.1, // Percentage of an element's area that needs to be visible to launch animation
        // threshold: 1, // Percentage of an element's area that needs to be visible to launch animation
      }
    },
    `gatsby-plugin-transition-link`,
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'HJYqFBdZr08fxdAVgr1GMAtt',
        homeSlug: 'home',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        resolveLinks: 'url'
      }
    },
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: 'gatsby-plugin-web-font-loader',
      options: {
        google: {
          families: ['Cabin:400,700']
        }
      }
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `GatsbyJS`,
        short_name: `GatsbyJS`,
        start_url: `/`,
        background_color: `#FCF5EC`,
        theme_color: `#00BA6B`,
        display: `standalone`,
        icon: `src/images/favicon.png`
      },
    },
    // {
    //   resolve: `gatsby-source-instagram`,
    //   options: {
    //     username: `3906910520`,
    //     access_token: "EAAtbZAwztUYkBAEYGYaHLfbKHuZC7SmYaROHEESHevx8FxEy2yLj2ozpbKGUU7t227pWKfjJDC5L98UWAspo0jlNPnGuIJuPYnVndqwsbTRlffKpnHXiI5v3ycVsWQO6YVxYZAGcZB2tp2xfPZC9IPBPY4XdmpqHpFe874NeruZASwzF7v4lyVhbgYtKgMDP4ZD",
    //     instagram_id: "17841404035608363",
    //     // paginate: 100,
    //     maxPosts: 20,
    //     // endpoint: "https://graph.instagram.com",  // optional, the default api endpoint is https://graph.facebook.com/v3.1, you can also choose https://graph.instagram.com as the base api endpoint, see https://developers.facebook.com/docs/instagram-basic-display-api
    //   },
    // },
  ],
}
